<template>
  <DetailTemplate
    :customClass="'user-detail detail-page detailpage-touchless'"
    v-if="getPermission('user:view')"
  >
    <template v-slot:sidebar-detail>
      <div class="d-flex ms-2">
        <v-switch
          @click="status_dialog = true"
          v-model="userArr.activated"
          class="mt-0"
          inset
          color="green"
        ></v-switch>
        <v-chip
          v-if="userArr.activated == 1"
          class="mx-2 custom-status font-weight-600 custom-grey-border d-inline-table text-uppercase justify-center font-8 text-white"
          color="#66bb6a"
          label
        >
          Active
        </v-chip>
        <v-chip
          v-if="userArr.activated == 0"
          class="mx-2 custom-status font-weight-600 custom-grey-border d-inline-table text-uppercase justify-center font-10 text-white"
          color="red"
          label
        >
          In-Active
        </v-chip>
        <!-- <template
            v-if="engineerArr && engineerArr.role && engineerArr.role.id == 2"
          >
            <v-chip label outlined text-color="" dense class="ml-2" color="blue"
              >Technician</v-chip
            >
          </template>
<template v-if="engineerArr && engineerArr.is_driver">
            <v-chip label outlined text-color="" dense class="ml-2" color="red">
              Driver</v-chip
            >
          </template> -->
      </div>
      <div class="mt-5">
        <v-row class="custom-content-container pt-0">
          <v-col md="12">
            <v-hover v-slot="{ hover }">
              <div class="p-10 text-center">
                <v-progress-circular
                  indeterminate
                  :size="100"
                  :width="10"
                  color="cyan"
                  v-if="pageLoading"
                ></v-progress-circular>
                <v-avatar size="200" v-else>
                  <v-img
                    max-height="200px"
                    max-width="200px"
                    :lazy-src="$defaultProfileImage"
                    :src="
                      getProfileImage ? getProfileImage : $defaultProfileImage
                    "
                    class="customer-image"
                  >
                  </v-img>
                </v-avatar>
                <template>
                  <div v-if="hover" class="user-image-action">
                    <v-btn
                      depressed
                      class="mx-4 custom-bold-button"
                      outlined
                      small
                      v-on:click="selectProfileImage"
                      color="cyan"
                    >
                      <template v-if="getProfileImage">Change Image</template>
                      <template v-else>Upload Image</template>
                    </v-btn>
                    <div class="d-none">
                      <v-file-input
                        v-model.trim="rawImage"
                        ref="uploadImageUser"
                        :rules="imageRules"
                        accept="image/png, image/jpeg, image/bmp"
                      ></v-file-input>
                    </div>
                  </div>
                </template>
              </div>
            </v-hover>
          </v-col>
          <v-col cols="12" class="pa-0">
            <div class="pa-3">
              <table class="width-100">
                <tr>
                  <th
                    colspan="2"
                    class="grey lighten-4 font-size-16 text-uppercase color-custom-blue py-1"
                  >
                    User Information
                  </th>
                </tr>
                <tr>
                  <td
                    style="width: 110px"
                    valign="top"
                    class="py-1 font-size-18 text-capitalize font-weight-500"
                  >
                    Full name
                  </td>
                  <td valign="top" class="py-1 font-size-18 text-capitalize">
                    <template v-if="userArr?.first_name">
                      {{ userArr?.first_name }}
                    </template>
                    <em v-else class="text-muted">no Full name</em>
                  </td>
                </tr>
                <tr>
                  <td
                    valign="top"
                    class="py-1 font-size-18 text-capitalize font-weight-500"
                  >
                    Designation
                  </td>
                  <td valign="top" class="py-1 font-size-18 text-capitalize">
                    <v-chip
                      v-if="userArr?.designation?.name"
                      color="cyan"
                      class="my-1 mr-1"
                      outlined
                      label
                      small
                    >
                      {{ userArr?.designation?.name }}
                    </v-chip>
                    <em v-else class="text-muted">no Designation</em>
                  </td>
                </tr>
                <tr>
                  <td
                    valign="top"
                    class="py-1 font-size-18 text-capitalize font-weight-500"
                  >
                    Role
                  </td>
                  <td valign="top" class="py-1 font-size-18 text-capitalize">
                    <template v-if="userArr?.role?.name">
                      {{ userArr?.role?.name }}
                    </template>
                    <em v-else class="text-muted">no Role</em>
                  </td>
                </tr>
                <tr>
                  <td
                    valign="top"
                    class="py-1 font-size-18 font-weight-500 text-capitalize"
                  >
                    Email
                  </td>
                  <td valign="top" class="py-1 font-size-18 text-lowercase">
                    <template v-if="userArr?.user_email">
                      {{ userArr?.user_email }}
                    </template>
                    <em v-else class="text-muted">no Email</em>
                  </td>
                </tr>
                <tr>
                  <td
                    valign="top"
                    class="py-1 font-size-18 font-weight-500 text-capitalize"
                  >
                    Phone No
                  </td>
                  <td valign="top" class="py-1 font-size-18 text-lowercase">
                    <template v-if="userArr?.phone_number">
                      {{ userArr?.phone_number }}
                    </template>
                    <em v-else class="text-muted">no Phone No</em>
                  </td>
                </tr>
              </table>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="mt-5 text-center">
        <v-btn
          depressed
          class="mx-2 custom-bold-button white--text mb-2"
          color="cyan"
          @click="signatureDialog = true"
          style="width: 80%"
        >
          Digital E Sign
        </v-btn>
        <v-icon
          style="
            font-size: 15px;
            margin-bottom: 15px !important;
            display: inline-block;
          "
          :color="attachment_url ? 'green' : 'red'"
          class="mt-1 mb-1"
          small
          >mdi-circle</v-icon
        >

        <v-btn
          depressed
          class="mx-2 custom-bold-button white--text mb-2"
          color="cyan"
          @click="emailSignature()"
          style="width: 80%"
        >
          Email Signtaure
        </v-btn>
        <v-icon
          style="
            font-size: 15px;
            margin-bottom: 15px !important;
            display: inline-block;
          "
          :color="userArr?.email_signature ? 'green' : 'red'"
          class="mt-1 mb-1"
          small
          >mdi-circle</v-icon
        >

        <v-btn
          depressed
          class="mx-2 custom-bold-button white--text"
          color="cyan"
          @click="resetPasswordDialog = true"
          style="width: 95%"
        >
          Reset Password
        </v-btn>
      </div>
    </template>
    <template v-slot:header-title>
      <h1 class="form-title margin-auto">
        {{ getPageTitle }}
      </h1>
    </template>
    <template v-slot:header-action>
      <div class="d-flex">
        <!-- {{ $route  }} -->
        <v-btn
          :disabled="pageLoading"
          class="mx-2 custom-bold-button"
          color="orange white--text"
          depressed
          @click="mailDialog = true"
        >
          Send Consent Email
        </v-btn>
        <v-btn
          v-if="false"
          :disabled="pageLoading"
          class="mx-2 custom-bold-button"
          v-on:click="status_dialog = true"
          :color="userArr.activated ? 'red white--text' : 'green white--text'"
          @click="status_dialog = true"
          depressed
        >
          {{ userArr.activated ? "Mark as In-Active" : "Mark as Active" }}
        </v-btn>
        <template v-if="getPermission('user:update')">
          <v-btn
            depressed
            :disabled="pageLoading || roleLoading"
            :loading="roleLoading"
            class="mx-2 custom-grey-border custom-bold-button text-white"
            color="cyan"
            v-on:click="getRoles"
          >
            <v-icon small left>mdi-pencil</v-icon>
            Edit
          </v-btn>
        </template>
        <v-btn
          depressed
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </div>
    </template>

    <template v-slot:body>
      <v-tabs
        v-model="userTab"
        background-color="transparent"
        color="cyan"
        class="custom-tab-transparent"
      >
        <template v-for="(tab, index) in tabs">
          <v-tab
            class="font-size-16 font-weight-600 px-8"
            :key="index"
            :href="'#tab-' + tab.key"
            :disabled="tab.disabled"
          >
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg
                :src="$assetURL('media/custom-svg/' + tab.key + '.svg')"
              />
              <!--end::Svg Icon-->
            </span>
            {{ tab.title }}
          </v-tab>
        </template>
      </v-tabs>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll user-detail-height"
        style="position: relative"
      >
        <v-tabs-items v-model="userTab" touchless>
          <v-tab-item value="tab-history">
            <RecentActivity :user="user"></RecentActivity>
          </v-tab-item>
          <v-tab-item value="tab-password">
            <PasswordUpdate
              :user="user"
              v-on:password:updated="userTab = 'tab-history'"
            ></PasswordUpdate>
          </v-tab-item>
          <v-tab-item value="tab-leave">
            <UserLeaveUpdate
              :user="user"
              v-on:leave:updated="userTab = 'tab-leave'"
            ></UserLeaveUpdate>
          </v-tab-item>
          <v-tab-item value="tab-file">
            <AdditionalDocuments
              :type-id="parseInt($route.params.id)"
              class="mx-4"
              type-text="User"
              :type="28"
              :extensive="true"
            ></AdditionalDocuments>
          </v-tab-item>

          <v-tab-item value="tab-signature">
            <br />
            <v-container class="ml-4">
              <v-layout style="max-width: 50%">
                <v-flex md6>
                  <v-img
                    contain
                    v-if="attachment_url"
                    :lazy-src="$defaultImage"
                    :src="attachment_url"
                  ></v-img>
                  <v-img
                    v-else
                    contain
                    :lazy-src="$defaultImage"
                    :src="$defaultImage"
                  ></v-img>
                  <br />
                  <v-btn
                    depressed
                    v-on:click="select_file()"
                    color="cyan white--text"
                    class="custom-bold-button"
                    label
                    >Change</v-btn
                  >
                  <v-file-input
                    ref="select-file"
                    class="d-none"
                    accept="image/png, image/jpeg, image/jpg"
                    v-model="signature"
                    dense
                    v-on:change="on_file_change($event)"
                    hide-details
                  ></v-file-input>
                </v-flex>
                <v-flex md6> </v-flex>
              </v-layout>
              <br />
              <v-layout
                class="signature-pad"
                id="signature-pad"
                style="max-width: 50%"
              >
                <div
                  ref="customer_signature_div"
                  style="width: 280px"
                  class="custom-border-grey-dashed signature-pad-wrap"
                >
                  <canvas
                    ref="customer_signature"
                    style="width: 280px; height: 120px !important"
                  ></canvas>
                </div>
                <hr />
                <div style="position: relative">
                  <v-btn
                    style="position: absolute; bottom: 0px; right: -14px"
                    content="Click here to clear signature"
                    v-tippy="{ arrow: true, placement: 'top' }"
                    icon
                    small
                    v-on:click="customer_signature.clear()"
                  >
                    <v-icon>mdi-close-circle-outline</v-icon>
                  </v-btn>
                </div> </v-layout
              ><br />
              <v-layout style="max-width: 50%">
                <v-btn
                  depressed
                  v-on:click="submitSignature"
                  color="cyan white--text"
                  class="custom-bold-button"
                  :disabled="valid_signature"
                  >Submit</v-btn
                >
              </v-layout>
            </v-container>
          </v-tab-item>
          <v-tab-item value="tab-login-log">
            <LoginLog :user="user" :logs="loginLogs"></LoginLog>
          </v-tab-item>
        </v-tabs-items>
      </perfect-scrollbar>

      <Dialog :commonDialog="updateDialog">
        <template v-slot:title> Edit User </template>
        <template v-slot:body>
          <v-form
            ref="userForm"
            v-model.trim="formValid"
            lazy-validation
            v-on:submit.stop.prevent="submitUser"
          >
            <v-row class="create-form">
              <v-col md="10" class="py-0 pt-4" offset-md="1">
                <v-row dense>
                  <v-col md="4" class="py-0">
                    <label
                      class="font-size-16 font-weight-500 ml-1 required"
                      for="display_name"
                      >Salutation</label
                    >
                    <v-select
                      :items="salutationList"
                      dense
                      filled
                      item-text="text"
                      item-value="value"
                      item-color="cyan"
                      placeholder="Salutation"
                      solo
                      flat
                      color="cyan"
                      v-model.trim="updateUser.title"
                      :rules="[
                        validateRules.required(updateUser.title, 'Salutation'),
                      ]"
                    ></v-select>
                  </v-col>
                  <v-col md="4" class="py-0">
                    <label
                      class="font-size-16 font-weight-500 ml-1 required"
                      for="display_name"
                      >First Name
                    </label>
                    <v-text-field
                      dense
                      filled
                      placeholder="First Name"
                      solo
                      flat
                      color="cyan"
                      v-model.trim="updateUser.first_name"
                      :rules="[
                        validateRules.required(
                          updateUser.first_name,
                          'First Name'
                        ),
                        validateRules.minLength(
                          updateUser.first_name,
                          'First Name',
                          2
                        ),
                        validateRules.maxLength(
                          updateUser.first_name,
                          'First Name',
                          100
                        ),
                      ]"
                    ></v-text-field>
                  </v-col>
                  <v-col md="4" class="py-0">
                    <label
                      class="font-size-16 font-weight-500 ml-1"
                      for="display_name"
                      >Last Name</label
                    >
                    <v-text-field
                      dense
                      filled
                      placeholder="Last Name"
                      solo
                      flat
                      color="cyan"
                      v-model.trim="updateUser.last_name"
                      :rules="[
                        validateRules.minLength(
                          updateUser.last_name,
                          'Last Name',
                          2
                        ),
                        validateRules.maxLength(
                          updateUser.last_name,
                          'Last Name',
                          100
                        ),
                      ]"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="10" class="py-0" offset-md="1">
                <v-row dense>
                  <v-col md="6" class="py-0">
                    <label
                      class="font-size-16 font-weight-500 ml-1 required"
                      for="display_name"
                      >Role</label
                    >
                    <v-select
                      :items="roles"
                      dense
                      filled
                      item-text="name"
                      item-value="id"
                      item-color="cyan"
                      placeholder="Role"
                      solo
                      flat
                      v-model.trim="updateUser.role"
                      color="cyan"
                      hide-details
                      :rules="[validateRules.required(updateUser.role, 'Role')]"
                    ></v-select>
                    <em class="font-size-10" v-if="updateUser.role == 1"
                      >Unrestricted access to all modules.</em
                    >
                  </v-col>
                  <v-col md="6" class="py-0">
                    <label
                      class="font-size-16 font-weight-500 ml-1 required"
                      for="display_name"
                      >Display Name</label
                    >
                    <v-text-field
                      dense
                      filled
                      placeholder="Display Name"
                      solo
                      flat
                      color="cyan"
                      v-model.trim="updateUser.display_name"
                      :rules="[
                        validateRules.required(
                          updateUser.display_name,
                          'Display Name'
                        ),
                        validateRules.minLength(
                          updateUser.display_name,
                          'Display Name',
                          2
                        ),
                        validateRules.maxLength(
                          updateUser.display_name,
                          'Display Name',
                          100
                        ),
                      ]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="6" class="py-0">
                    <label
                      class="font-size-16 font-weight-500 ml-1 required"
                      for="display_name"
                      >Email</label
                    >
                    <v-text-field
                      dense
                      filled
                      placeholder="Email"
                      solo
                      flat
                      color="cyan"
                      v-model.trim="updateUser.user_email"
                      :rules="[
                        validateRules.validEmail(
                          updateUser.user_email,
                          'Email'
                        ),
                        validateRules.required(updateUser.user_email, 'Email'),
                        validateRules.minLength(
                          updateUser.user_email,
                          'Email',
                          2
                        ),
                        validateRules.maxLength(
                          updateUser.user_email,
                          'Email',
                          100
                        ),
                      ]"
                    ></v-text-field>
                  </v-col>

                  <v-col md="6" class="py-0">
                    <label
                      class="font-size-16 font-weight-500 ml-1 required"
                      for="display_name"
                      >Phone Number</label
                    >
                    <PhoneTextField
                      :key="1"
                      id="t-primary_phone"
                      v-model="updateUser.phone_number"
                      :value="updateUser.phone_number"
                      required
                    >
                    </PhoneTextField>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col md="6" class="py-0">
                    <label
                      class="font-size-16 font-weight-500 ml-1 required"
                      for="Designation"
                      >Designation</label
                    >
                    <v-autocomplete
                      :items="userDesignations"
                      v-model.trim="updateUser.designation"
                      dense.
                      filled
                      color="cyan"
                      item-color="cyan"
                      solo
                      flat
                      append-outer-icon="mdi-cog"
                      placeholder="Select Designation"
                      v-on:click:append-outer="manageBrandDialog = true"
                      item-text="text"
                      item-value="value"
                      class="width-100 new-height"
                      :rules="[
                        validateRules.required(
                          updateUser.designation,
                          'Designation'
                        ),
                      ]"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Designation(s) Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                    <template>
                      <ManageUserdesignation
                        :dialog="manageBrandDialog"
                        :e_designation="userDesignations"
                        v-on:close-dialog="manageBrandDialog = false"
                        v-on:get-e-designation="getOptions"
                      ></ManageUserdesignation>
                    </template>
                  </v-col>
                </v-row>
                <!-- <v-row>
                    <v-col md="6" class="py-0">
                      <label class="font-weight-700 font-size-16"
                        >From Amount</label
                      >
                      <v-text-field
                        v-model.trim="updateUser.from_amount"
                        id="amount"
                        type="number"
                        dense
                        filled
                        placeholder="From"
                        solo
                        flat
                        color="cyan"
                        v-on:keypress="
                          limitDecimal($event, updateUser.from_amount)
                        "
                      ></v-text-field>
                    </v-col>
                    <v-col md="6" class="py-0">
                      <label class="font-weight-700 font-size-16"
                        >To Amount</label
                      >
                      <v-text-field
                        v-model.trim="updateUser.to_amount"
                        id="to"
                        type="number"
                        dense
                        filled
                        placeholder="To"
                        solo
                        flat
                        color="cyan"
                        v-on:keypress="limitDecimal($event, updateUser.to_amount)"
                      ></v-text-field>
                    </v-col>
                  </v-row> -->
                <!--  <v-row v-for="(type, index) in leaveList" :key="index">
                    <v-col md="6" class="pb-4">
                      <v-text-field
                        v-model.trim="type.category"
                        dense
                        filled
                        placeholder="Category"
                        solo
                        flat
                        hide-details
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                    <v-col md="5" class="pb-4">
                      <v-text-field
                        v-model.trim="type.maxdays"
                        dense
                        filled
                        placeholder="Max Days"
                        solo
                        flat
                        type="number"
                        min="0"
                        max="999"
                        hide-details
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                    <v-col md="1" class="py-0 mt-2">
                      <v-checkbox
                        multiple
                        :disabled="pageLoading"
                        v-model="updateUser.categories"
                        color="cyan"
                        class="my-0"
                        dense
                        :value="type.id"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                  </v-row> -->
              </v-col>
            </v-row>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            depressed
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            :disabled="!formValid || formLoading"
            :loading="formLoading"
            v-on:click="submitUser"
          >
            Update
          </v-btn>
          <v-btn
            depressed
            :disabled="formLoading"
            class="custom-grey-border custom-bold-button"
            v-on:click="updateDialog = false"
          >
            Cancel
          </v-btn>
        </template>
      </Dialog>
      <Dialog :commonDialog="signatureDialog">
        <template v-slot:title>Signature</template>
        <template v-slot:body>
          <v-container class="ml-4">
            <v-layout style="max-width: 50%">
              <v-flex md6>
                <v-img
                  contain
                  v-if="attachment_url"
                  :lazy-src="$defaultImage"
                  :src="attachment_url"
                ></v-img>
                <v-img
                  v-else
                  contain
                  :lazy-src="$defaultImage"
                  :src="$defaultImage"
                ></v-img>
                <br />
                <v-btn
                  depressed
                  v-on:click="select_file()"
                  color="cyan white--text"
                  class="custom-bold-button"
                  label
                  >Change</v-btn
                >
                <v-file-input
                  ref="select-file"
                  class="d-none"
                  accept="image/png, image/jpeg, image/jpg"
                  v-model="signature"
                  dense
                  v-on:change="on_file_change($event)"
                  hide-details
                ></v-file-input>
              </v-flex>
              <v-flex md6> </v-flex>
            </v-layout>
            <br />
            <v-layout
              class="signature-pad"
              id="signature-pad"
              style="max-width: 50%"
            >
              <div
                ref="customer_signature_div"
                style="width: 280px"
                class="custom-border-grey-dashed signature-pad-wrap"
              >
                <canvas
                  ref="customer_signature"
                  style="width: 280px; height: 120px !important"
                ></canvas>
              </div>
              <hr />
              <div style="position: relative">
                <v-btn
                  style="position: absolute; bottom: 0px; right: -14px"
                  content="Click here to clear signature"
                  v-tippy="{ arrow: true, placement: 'top' }"
                  icon
                  small
                  v-on:click="customer_signature.clear()"
                >
                  <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
              </div> </v-layout
            ><br />
            <v-layout style="max-width: 50%">
              <!--   <v-btn depressed v-on:click="submitSignature" color="cyan white--text"
                                class="custom-bold-button" :disabled="valid_signature">Submit</v-btn> -->
            </v-layout>
          </v-container>
        </template>
        <template v-slot:action>
          <v-btn
            depressed
            v-on:click="submitSignature"
            color="cyan white--text"
            class="custom-bold-button"
            :disabled="valid_signature || signatureDialogLoading"
            >Submit</v-btn
          >
          <v-btn
            depressed
            class="mx-2 custom-grey-border custom-bold-button"
            :disabled="signatureDialogLoading"
            @click="signatureDialog = false"
          >
            Cancel
          </v-btn>
        </template>
      </Dialog>
      <Dialog :common-dialog="resetPasswordDialog">
        <template v-slot:title>Reset Password</template>
        <template v-slot:body>
          <PasswordUpdate
            ref="passwordUpdateRef"
            :user="user"
            v-on:password:updated="resetPasswordSuccess"
            v-on:loading="($event) => (resetPasswordLoading = $event)"
            hideInternalBtn
          ></PasswordUpdate>
        </template>
        <template v-slot:action>
          <v-btn
            depressed
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            :loading="resetPasswordLoading"
            @click="resetPassword()"
          >
            update Password
          </v-btn>
          <v-btn
            depressed
            class="mx-2 custom-grey-border custom-bold-button"
            :disabled="resetPasswordLoading"
            @click="resetPasswordDialog = false"
          >
            Cancel
          </v-btn>
        </template>
      </Dialog>
      <Dialog :commonDialog="eSignatureDialog">
        <template v-slot:title>Email Signature</template>
        <template v-slot:body>
          <v-container>
            <TinyMCE v-model="email_signature" />
          </v-container>
        </template>
        <template v-slot:action>
          <v-btn
            depressed
            v-on:click="submitEmailSignature"
            color="cyan white--text"
            class="custom-bold-button"
            :disabled="!email_signature"
            >Submit</v-btn
          >
          <v-btn
            depressed
            class="mx-2 custom-grey-border custom-bold-button"
            @click="eSignatureDialog = false"
          >
            Cancel
          </v-btn>
        </template>
      </Dialog>
      <send-mail
        isUser
        :mail-dialog="mailDialog"
        title="Send as Email"
        type="user"
        v-on:close="mailDialog = false"
        :type-id="invoiceId"
        v-on:success="getInvoice()"
      ></send-mail>
      <Dialog :commonDialog="status_dialog" dense :dialogWidth="600">
        <template v-slot:title>
          <p class="mb-0">
            Are you sure, you want to mark as
            <span
              :class="{
                'red--text': userArr.activated == 1,
                'green--text': userArr.activated == 0,
              }"
              >{{ userArr.activated ? "deactivated" : "activated" }}</span
            >?
          </p>
        </template>
        <template v-slot:body>
          <v-container class="pa-2">
            <v-layout style="min-height: 60px">
              <v-flex md12 class="ml-5 d-flex align-baseline">
                <v-checkbox
                  dense
                  hide-details
                  v-model="set_permanent_delete"
                  :true-value="1"
                  :false-value="0"
                  color="red lighten-1"
                  class="secondary-label"
                >
                </v-checkbox>
                <span>
                  Would you like to delete this user and their data's after
                  <v-menu bottom offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        label
                        class="blue--text py-0 mx-1"
                        plain
                        hide-details
                        v-bind="attrs"
                        v-on="on"
                      >
                        <strong>{{ isDays }}</strong
                        ><v-icon>mdi-chevron-down</v-icon>
                      </v-chip>
                    </template>
                    <v-list>
                      <v-list-item
                        link
                        v-for="(item, i) in activeDaysList"
                        :key="i"
                        @click.stop="selectDays(item)"
                      >
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  ?
                </span>
              </v-flex>
            </v-layout>
          </v-container>
        </template>
        <template v-slot:action>
          <div>
            <v-btn
              :disabled="s_loading"
              :loading="s_loading"
              color="blue darken-4 white--text"
              class="mr-2"
              v-on:click="activeInactive"
              tile
              depressed
              >Yes! Update</v-btn
            >
            <v-btn
              :disabled="s_loading"
              v-on:click="status_dialog = false"
              tile
              depressed
              >No! Cancel</v-btn
            >
          </div>
        </template>
      </Dialog>
    </template>
  </DetailTemplate>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DetailTemplate from "@/view/pages/partials/Detail-Template";
import Dialog from "@/view/pages/partials/Dialog";
import { GET, PATCH, UPLOAD, PUT } from "@/core/services/store/request.module";
import LoginLog from "@/view/pages/user/detail/LogsLoginActivity";
import PasswordUpdate from "@/view/pages/user/detail/PasswordUpdate";
import UserLeaveUpdate from "@/view/pages/user/detail/Leave-Update";
import AdditionalDocuments from "@/view/pages/engineer/Additional-Documents";
import RecentActivity from "@/view/pages/user/detail/RecentActivity";
/* import RecentActivity from "@/view/pages/user/detail/LogsLoginActivity"; */
import ValidationMixin from "@/core/plugins/validation-mixin";
import ObjectPath from "object-path";
import SignaturePad from "signature_pad";
import PhoneTextField from "@/view/pages/partials/PhoneTextField";
import ManageUserdesignation from "@/view/pages/partials/Manage-Userdesignation.vue";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import SendMail from "@/view/pages/partials/Send-Mail";
export default {
  mixins: [CommonMixin, ValidationMixin, FileManagerMixin],
  name: "user-detail",
  data() {
    return {
      userDesignations: [],
      set_permanent_delete: 0,
      s_loading: false,
      mailDialog: false,
      status_dialog: false,
      profileImage: null,
      rawImage: null,
      invoiceId: 0,
      signatureDialog: false,
      resetPasswordDialog: false,
      resetPasswordLoading: false,
      signatureDialogLoading: false,
      user: 0,
      userTab: "tab-signature",
      pageLoading: true,
      roleLoading: false,
      updateDialog: false,
      formValid: true,
      formLoading: false,
      eSignatureDialog: false,
      manageBrandDialog: false,
      loginLogs: {},
      leaveList: [],
      userArr: {},
      roles: [],
      isActive: null,
      isDays: "15 Days",
      activeDaysList: [
        { title: "10 Days", value: "10_days" },
        { title: "15 Days", value: "15_days" },
        { title: "30 Days", value: "30_days" },
      ],
      tabs: [
        /* {
                  title: "Recent Activities",
                  icon: "mdi-credit-card",
                  key: "history",
                  disabled: false,
                }, */
        {
          title: "Login Log",
          icon: "mdi-credit-card",
          key: "login-log",
          disabled: false,
        },
        /*  {
                   title: "Signature",
                   icon: "mdi-credit-card",
                   key: "signature",
                   disabled: false,
                 },
                 {
                   title: "Password",
                   icon: "mdi-credit-card",
                   key: "password",
                   disabled: false,
                 },
         
                 {
                   title: "Leave",
                   icon: "mdi-credit-card",
                   key: "leave",
                   disabled: false,
                 }, */
        {
          title: "Additional Document",
          icon: "mdi-credit-card",
          key: "file",
          disabled: false,
        },
      ],
      signature: null,
      customer_signature: null,
      email_signature: null,
      attachment_url: null,
      updateUser: {
        role: null,
        title: null,
        first_name: null,
        last_name: null,
        display_name: null,
        user_email: null,
        phone_number: null,
        designation: null,
        from_amount: 0,
        to_amount: 0,
      },
    };
  },
  watch: {
    rawImage(param) {
      const _this = this;
      if (_this.lodash.isEmpty(_this.userArr) === false) {
        this.pageLoading = true;
        _this.uploadAttachment(param).then((response) => {
          _this.profileImage = _this.lodash.head(response);
          if (_this.lodash.isEmpty(_this.profileImage) === false) {
            _this.$store
              .dispatch(PATCH, {
                url: "user-profile/" + _this.userArr.id,
                data: {
                  profile_logo: _this.profileImage.id,
                },
              })
              .then(() => {
                this.pageLoading = false;
              })
              /* .than(() => {
                                console.log(21121)
                                this.pageLoading = false;
                            }) */
              .catch((error) => {
                _this.logError(error);
              });
          }
        });
      }
    },
    signatureDialog(param) {
      if (param) {
        let _this = this;
        setTimeout(function () {
          _this.initSignature();
        }, 500);
      }
    },
  },
  methods: {
    selectDays(item) {
      this.isDays = item.title;
    },
    selectProfileImage() {
      this.$refs["uploadImageUser"].$refs["input"].click();
    },
    select_file() {
      this.$refs["select-file"].$refs["input"].click();
    },
    resetPassword() {
      this.$refs.passwordUpdateRef.onSubmit();
    },
    resetPasswordSuccess() {
      this.resetPasswordDialog = false;
    },
    on_file_change(file) {
      if (file) {
        this.attachment_url = URL.createObjectURL(file);
        let formData = new FormData();
        formData.append("files[0]", file, file.name);
        this.$store
          .dispatch(UPLOAD, { url: "file-manager/upload", data: formData })
          .then((response) => {
            this.attachment_url = ObjectPath.get(response, "data.0.file.url");
            this.$refs["select-file"].reset();
            this.update_signature();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    activeInactive(data) {
      const _this = this;
      // _this.status_dialog = true;
      if (data.activated == false) {
        data.activated = 0;
      } else {
        data.activated = 1;
      }
      _this.$store
        .dispatch(PATCH, {
          url: `single-user/${data.activated}/${data.id}`,
          data: {
            id: data.id,
            status: data.activated,
          },
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.getRows();
        });
    },
    update_signature() {
      this.$store
        .dispatch(PATCH, {
          url: `user/${this.user}/signature/update`,
          data: { signature: this.attachment_url },
        })
        .catch((error) => {
          this.logError(error);
        });
    },

    submitSignature() {
      const _this = this;

      if (_this.customer_signature.isEmpty()) {
        return false;
      }
      _this.validationErrorBag = new Array();
      this.signatureDialogLoading = true;
      _this.$store
        .dispatch(PUT, {
          url: `user/${this.user}/signature/update`,
          data: {
            customer: _this.customer_signature.toDataURL(),
          },
        })
        .then(() => {
          this.signatureDialog = false;
          this.signatureDialogLoading = false;
          /* this.getUser(); */
        })
        .catch(({ response }) => {
          if (response && response.data && response.data.message) {
            _this.validationErrorBag = response.data.message.split("<br>");
          } else {
            _this.validationErrorBag.push(
              "Something went wrong, Please try again later."
            );
          }
        })
        .finally(() => {
          _this.loadContent();
          _this.customer_signature.clear();
        });
    },

    submitEmailSignature() {
      const _this = this;
      this.signatureDialogLoading = true;
      _this.$store
        .dispatch(PUT, {
          url: `user/${this.user}/email-signature/update`,
          data: {
            customer: this.email_signature,
          },
        })
        .then(() => {
          this.eSignatureDialog = false;
          this.signatureDialogLoading = false;
          /* this.getUser(); */
        })
        .catch(() => {})
        .finally(() => {
          _this.loadContent();
        });
    },
    emailSignature() {
      if (!this.userArr?.email_signature) {
        this.email_signature = null;
      } else {
        this.email_signature = this.userArr?.email_signature;
      }
      this.eSignatureDialog = true;
    },
    submitUser() {
      const _this = this;
      if (!_this.$refs.userForm.validate()) {
        return false;
      }

      _this.formLoading = true;

      _this.$store
        .dispatch(PATCH, {
          url: "user/" + _this.user,
          data: _this.updateUser,
        })
        .then(() => {
          _this.updateDialog = false;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.loadContent();
          _this.formLoading = false;
        });
    },
    /*updateOrcreate(categoriesid) {
          const _this = this; 
          _this.pageLoading = true;
          _this.$store
            .dispatch(PUT, { url: "setting/leave/" + categoriesid,
            data: {categories:_this.leaveList} })
            .catch((error) => {
              _this.logError(error);
            })
            .finally(() => {
              _this.pageLoading = false;
            });
        },*/
    getUser() {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(GET, { url: "user/" + _this.user })

            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getOptions() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "get-user-designation",
        })
        .then(({ data }) => {
          _this.userDesignations = this.lodash.map(
            data.user_designation,
            function (row) {
              return {
                text: row.text,
                value: row.id,
              };
            }
          );
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getLoginActivity() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: `login-activity/${_this.user}`,
        })
        .then(({ data }) => {
          _this.loginLogs = data || {};
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getRoles() {
      const _this = this;
      _this.roleLoading = true;
      _this.updateUser = {
        role: _this.userArr.role ? _this.userArr.role.id : 0,
        title: _this.lodash.lowerCase(_this.userArr.title),
        first_name: _this.userArr.first_name,
        last_name: _this.userArr.last_name,
        display_name: _this.userArr.display_name,
        user_email: _this.userArr.user_email,
        phone_number: _this.userArr.phone_number,
        designation: _this.userArr.designation
          ? _this.userArr.designation.id
          : null,
        from_amount: _this.userArr.from_amount,
        to_amount: _this.userArr.to_amount,
      };
      _this.$store
        .dispatch(GET, { url: "user-role" })
        .then(({ data }) => {
          _this.roles = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.updateDialog = true;
          _this.roleLoading = false;
        });
    },
    /*  getLeaveDays() {
          const _this = this;
          _this.pageLoading = true;
          _this.$store
            .dispatch(GET, {
              url: "setting/leave",
            })
            .then(({ data }) => {
              _this.leaveList = data;
            })
            .catch((error) => {
              _this.logError(error);
              _this.goBack();
            })
            .finally(() => {
              _this.pageLoading = false;
            });
        }, */
    loadContent() {
      const _this = this;
      _this.pageLoading = true;
      _this
        .getUser()
        .then((response) => {
          _this.userArr = response;
          _this.attachment_url = response.signature;
          /*  _this.loginLogs = response.login_details || []; */
        })
        .catch((error) => {
          _this.logError(error);
          _this.$router.go(-1);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    initSignature() {
      const _this = this;
      if (_this.signatureDialog) {
        _this.$nextTick(() => {
          let ccanvas = _this.$refs["customer_signature"];
          let ccanvasDiv = _this.$refs["customer_signature_div"];
          // let cparentDiv = document.getElementById("signature-pad");
          let cparentWidth = 280; //cparentDiv.offsetWidth / 2;

          ccanvas.setAttribute("width", cparentWidth);

          ccanvasDiv.setAttribute("style", "width:" + cparentWidth + "px");

          _this.customer_signature = new SignaturePad(ccanvas);
        });
      }
    },
  },
  components: {
    DetailTemplate,
    LoginLog,
    Dialog,
    PasswordUpdate,
    ManageUserdesignation,
    UserLeaveUpdate,
    RecentActivity,
    PhoneTextField,
    AdditionalDocuments,
    TinyMCE,
    SendMail,
  },
  mounted() {
    const _this = this;
    _this.getOptions();
    /* _this.getLeaveDays(); */
    _this.loadContent();
    _this.getLoginActivity();
    _this.initSignature();
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "User", route: "user" },
      { title: "Detail" },
    ]);
  },
  created() {
    const _this = this;
    _this.user = _this.lodash.toSafeInteger(_this.$route.params.id);
    if (!_this.user || _this.user <= 0) {
      _this.$router.go(-1);
    }
  },
  computed: {
    getPageTitle() {
      return this.userArr ? this.userArr.full_name : "";
    },
    valid_signature() {
      return this.customer_signature
        ? this.customer_signature.isEmpty()
        : false;
    },
    getProfileImage() {
      const _this = this;
      let result = null;
      if (_this.lodash.isEmpty(_this.profileImage) === false) {
        return _this.profileImage.file.url;
      }
      if (
        _this.lodash.isEmpty(_this.userArr) === false &&
        _this.lodash.isEmpty(_this.userArr.profile_logo) === false &&
        _this.lodash.isEmpty(_this.userArr.profile_logo.file) === false
      ) {
        result = _this.userArr.profile_logo.file.url;
      }
      return result;
    },
  },
};
</script>
<style scoped>
.v-text-field--filled > .v-input__control > .v-input__slot {
  min-height: 100%;
}

.v-input input {
  max-height: 30px;
}
</style>
